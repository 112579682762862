<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <NavBar />
  <router-view />
  <FooTer />

</template>
<script>
// import { ref, watch, onMounted } from 'vue';

// const metaContent = ref('v1.0.1');

// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import NavBar from '@/components/layout/navbar.vue'
import FooTer from '@/components/layout/footer.vue'
export default {
  name: 'HomeView',
  components: {
    NavBar, FooTer
  },
  watch: {
    $route(to) {
      this.loadLanguageStyles(to.path);
    }
  },
  methods: {
    updateMetaTag(content) {
      const metaTag = document.querySelector('meta[name="cache-version"]');
      if (metaTag) {
        metaTag.setAttribute('content', content);
      } else {
        const meta = document.createElement('meta');
        meta.name = 'cache-version';
        meta.content = content;
        document.head.appendChild(meta);
      }
    },
    loadLanguageStyles(path) {
      if (path.startsWith('/ar')) {
        // Dynamically import Arabic styles
        import('./assets/styles/arabicStyle.scss').then(() => {
          console.log('Arabic styles loaded');
        });
      } else if (path.startsWith('/en')) {
        // Dynamically import English styles
        import('./assets/styles/englishStyle.scss').then(() => {
          console.log('English styles loaded');
        });
      }
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
